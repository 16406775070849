<template>
  <div class="sales-parameter">
    <PageTitle
      title="電商連結"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'EcommerceParameterSetting' })"
    />

    <section class="section-block">
      <SectionTitle title="連結設定" @edit="linkSettingDialog = true" />

      <el-form :model="config" label-position="left" label-width="200px">
        <el-form-item label="子網域">
          <div class="secret-text collapse">
            <span>{{ config.domain ? config.domain : '尚未設定' }}</span>
          </div>
        </el-form-item>
        <el-form-item label="客服連結">
          <div class="secret-text collapse">
            <span>{{
              config.customerServiceLink
                ? config.customerServiceLink
                : '尚未設定'
            }}</span>
          </div>
        </el-form-item>

        <el-form-item label="電商連結">
          <div class="copy-block">
            <a class="secret-text">
              {{ ecommerceLink }}
            </a>
            <div>
              <el-button
                class="copy-btn"
                type="primary"
                @click="copyLink(ecommerceLink)"
              >
                複製
              </el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </section>

    <!-- 連結設定 Dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="linkSettingDialog"
      title="編輯連結設定"
      @close="resetForm"
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="子網域設定" prop="domain">
          <el-input
            v-model="formData.domain"
            :min="6"
            class="dialog-input"
            placeholder="請輸入"
          />
          <p style="font-size: 12px; color: gray;">
            最少6字元小寫英數組合，可包含一個 '-'
            符號，不得有其他特殊符號
          </p>
        </el-form-item>
        <el-form-item label="客服連結設定" prop="customerLink">
          <el-input
            v-model="formData.customerLink"
            :min="6"
            class="dialog-input"
            placeholder="請輸入"
          />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="linkSettingDialog = false">取消</el-button>
        <el-button type="primary" @click="updateDomain">儲存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import SectionTitle from '@/components/Title/SectionTitle'
import copy from 'clipboard-copy'

import { isDigitRules, noEmptyRules, urlRules, domainRules, emailRules } from '@/validation'
import formUtils from '@/utils/form'
import { GetConfig, UpdateEcommerceConfig } from '@/api/ecommerce/paramter'
import configuration from '@/configuration'
import { keys, map, filter, forEach } from 'lodash'

export default {
  name: 'LinkSettings',
  components: { PageTitle, SectionTitle },
  data: () => ({
    config: {},

    linkSettingDialog: false,

    formData: {
      domain: '',
      customerLink: '',
    },
    formRules: {
      domain: [
        noEmptyRules(),
        domainRules(),
        { min: 6, message: '最少6個字元' },
      ],
      customerLink: [noEmptyRules(), urlRules()],
      ecommerceLink: [noEmptyRules()],
    },
  }),
  computed: {
    shopId () {
      return this.$store.getters.shop
    },
    ecommerceLink () {
      const domain = this.config.domain
      if (!domain) return '尚未設定'

      return `https://${domain}.${configuration('ecommerceHost')}`
    },
  },

  async mounted () {
    await this.getConfig()
  },
  methods: {
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.getConfig()
    },
    async getConfig () {
      const [res, error] = await GetConfig({
        shopId: this.shopId,
      })
      if (error) {
        this.$message.warning(error)
        this.config = {
          Logo: null,
          domain: '',
        }
        this.formData.domain = ''

        await UpdateEcommerceConfig({
          shopId: this.shopId,
          enableOrderInvoice: false,
          enableOrderInvoiceB2B: false,
          enableOrderInvoiceLoveCode: false,
          enableOrderInvoiceCarrier: false,
          enableOrderInvoicePrint: false,
          enableOrderWallet: true,
          paymentDeadlineHour: 48,
          socialLinks: [],
        })
        return
      }
      this.config = res
      this.formData.domain = res.domain
      this.formData.customerLink = res.customerServiceLink
    },

    // => 更新子網域
    async updateDomain () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        domain: this.formData.domain,
        customerServiceLink: this.formData.customerLink,
      })

      if (error) this.$message.error(error)
      this.linkSettingDialog = false
      await this.getConfig()
      if (!error) return this.$message.success('更新成功！')
    },

    copyLink (link) {
      copy(link)
      this.$message.success('已成功複製連結！')
    },
  },
}
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
section {
  margin-top: 2rem;
  margin-bottom: 50px;
}

.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy-block {
  @apply flex items-center overflow-hidden max-w-[440px];

  a {
    @apply overflow-hidden text-gray-80;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
